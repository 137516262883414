import { Injectable } from '@angular/core';
import { ProcessorModel } from '../processor-model';
import { Processor } from '../processor';
import { ProcessingResult } from '../processing-result';
import { UserProfileService } from '../../user/user-profile.service.service';
import * as _ from 'lodash';
import { VcallApiService } from '../../api/vcall/vcall-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationChangeProcessor implements Processor {
  name = 'Notifying AS400';
  mustWaitForFinish: boolean;
  stopIfError: any;
  hasStopProcessing: boolean = false;

  constructor(
    private vcallService: VcallApiService,
    private userProfileService: UserProfileService
  ) {}

  async Process(model: ProcessorModel): Promise<ProcessingResult> {
    let self = this;
    const httpSuccess: number = 200;
    self.hasStopProcessing = false;

    let userProfile = this.userProfileService.getUserProfile();
    let applicationId = model.application.id; 

    var payload = {
      applicationId: applicationId,
      user: userProfile.domainUserName,
      ApplicationURL: window.location.origin
    };    

    await self.vcallService.updateContact(payload);

    return Promise.resolve({
      result: true,
      stopProcessing: this.hasStopProcessing,
    });
  }
}
